button {
  background: transparent;
}

.no-select,
svg {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

*[hidden],
*[data-hid],
.gone {
  display: none !important;
}

a {
  text-decoration: none;
}
.light {
  font-weight: 300;
}
.mb {
  font-weight: 500;
}
.b {
  font-weight: 700;
}

.vertical,
.v {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.horizontal,
.h {
  display: flex;
  flex-direction: row;
}
.center-justified,
.c {
  justify-content: center;
}
.justified,
.j {
  justify-content: space-between;
}

.end-justified,
.ej {
  justify-content: flex-end;
}

.wrap,
.w {
  flex-wrap: wrap;
}

.vertical-center,
.vc {
  align-items: center;
}

.fs {
  align-items: flex-start;
}

.grow,
.g {
  flex-grow: 1;
}

.center {
  align-items: center;
}

.center-text {
  text-align: center;
}

.hcenter {
  justify-content: center;
}
.as-fs {
  align-self: flex-start;
}

.cp {
  cursor: pointer;
}
.transition {
  transition: 0.3s;
}

.whitebox {
  background: #fff;
  border-radius: 0.5rem;
  padding: 1rem;
  margin-left: 0.5rem;
  margin-bottom: 0.8125rem;
  box-shadow: 1px 1px #eee;
}

.arrow {
  outline: none;
  width: 2.125rem;
  height: 2.125rem;
  display: block;
  margin: 0.5rem;
  user-select: none;
  cursor: pointer;
  padding: 0.75rem;
  box-sizing: border-box;
  border-radius: 50%;
  background: transparent;
  transition: background-color 0.5s;
}

.disable,
*[data-disable] {
  pointer-events: none;
  opacity: 0.5 !important;
  cursor: default;
}

.arrow:active,
.arrow:hover {
  background: #f5f5f5;
}

.arrow.end {
  transform: scaleX(-1);
}

.component-title {
  color: #4a4a4a;
  font-size: 1.5rem;
  font-weight: 500;
}

.nav-links {
  margin-right: 0.625rem;
}
.nav-links > a {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: bold;
  color: #4a4a4a;
  font-size: 0.75rem;
  white-space: nowrap;
}
.nav-links > .arrow {
  pointer-events: none;
  opacity: 0.4;
  margin: 0;
}
.component-whitebox {
  position: relative;
  border: 1px solid #ececec;
  border-radius: 5px;
  background-color: #fff;
  margin-top: 1rem;
}
.component-whitebox.paper-padding {
  padding: 10px 0px 10px 10px;
}
.avatar {
  width: 4.875rem;
  min-width: 4.875rem;
  height: 4.875rem;
  margin-right: 1.13875rem;
  object-fit: cover;
}
.round {
  border-radius: 50%;
}

.singleline {
  white-space: nowrap;
}

.elipsis,
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.no-overflow {
  overflow: hidden;
}

.MuiCircularProgress-colorPrimary {
  color: #33bdc7 !important;
}

.MuiButton-containedPrimary {
  color: #fff !important;
  background-color: #32bdc7 !important;
}

.MuiButton-containedPrimary.Mui-disabled {
  color: #fff !important;
  box-shadow: none !important;
  background-color: rgb(164, 164, 164, 0.6) !important;
}

.dropdown-list {
  overflow-y: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

.dropdown-item {
  min-width: 150px;
  padding: 0 12px;
  line-height: 40px;
  cursor: pointer;
}
.dropdown-item > span {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.dropdown-item.td-selected,
.dropdown-item[item-selected] {
  background-color: #f1f3f4;
}
.dropdown-item.td-hover {
  background-color: #eceff1;
}

.chip {
  display: block;
  position: relative;
  max-width: 174px;
  height: 29px;
  color: #4a4a4a;
  font-size: 14px;
  line-height: 29px;
  background-color: #ececec;
  border-radius: 14.5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 10px;
  padding-right: 28px;
  margin-right: 5px;
  margin-bottom: 5px;
}

.chip-delete {
  position: absolute;
  right: 6px;
  top: 7px;
  width: 16px;
  height: 16px;
  padding: 2px;
  border-radius: 50%;
  cursor: pointer;
  outline: none;
}
.chip-delete:hover,
.chip-delete:focus {
  background: #cecece;
}

.chip-no-cross {
  padding-right: 10px;
}
.chip-no-cross .chip-delete {
  display: none;
}

.chip-gray-border {
  border: 1px solid #a4a4a4;
  background-color: #fff;
  color: #7c7c7c;
  padding-right: 10px;
  max-width: 420px;
}
.chip-big {
  max-width: 420px;
  padding-right: 10px;
}

.no-shrink {
  flex-shrink: 0;
}

body:not([ext])::-webkit-scrollbar,
.sbar::-webkit-scrollbar {
  background: transparent;
  height: 8px;
  width: 8px;
}

body:not([ext])::-webkit-scrollbar-thumb,
.sbar::-webkit-scrollbar-thumb {
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: #dadce0;
  -webkit-border-radius: 8px;
  border-radius: 8px;
  min-height: 40px;
}

body:not([ext]):hover::-webkit-scrollbar-thumb,
.ssbar:hover::-webkit-scrollbar-thumb {
  background: #bdc1c6;
}

/* Thin scrollbar */
.thin-scrollbar::-webkit-scrollbar {
  background: transparent;
  height: 8px;
  width: 3px;
}

.thin-scrollbar::-webkit-scrollbar-thumb {
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: #dadce0;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  min-height: 10px;
}

.thin-scrollbar:hover::-webkit-scrollbar-thumb {
  background: #bdc1c6;
}
/* End Thin scrollbar */

/* Homage green button */
.ha-btn-green {
  background: #32bdc7 !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 1.5 !important;
  text-transform: none !important;
  box-shadow: none !important;
  color: white !important;
}
.ha-btn-green:hover {
  box-shadow: 1px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
}
.ha-btn-green-outline {
  color: #32bdc7 !important;
  border: 1px solid #32bdc7;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 1.5 !important;
  text-transform: none !important;
  box-shadow: none !important;
  transition: background-color 0.2s ease-out;
}
.ha-btn-green-outline:hover,
.ha-btn-green-outline:focus {
  background: #e8fcff;
}
/* End Homage green button */

/* Homage red button */
.ha-btn-red {
  background: #d92f44 !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 1.5 !important;
  text-transform: none !important;
  box-shadow: none !important;
  color: white !important;
}
.ha-btn-red:hover {
  box-shadow: 1px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
}
/* End Homage green button */

/* Homage clear button */
.ha-btn-clear {
  background: transparent !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 1.5 !important;
  text-transform: none !important;
  box-shadow: none !important;
  color: #4a4a4a !important;
}
.ha-btn-clear:hover {
  box-shadow: 1px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
}
.ha-btn-clear:disabled {
  background: transparent !important;
  color: #4a4a4a !important;
  opacity: 0.5;
}
/* End Homage green button */

/* Homage radio button style */
.ha-rb .MuiRadio-colorSecondary.Mui-checked {
  color: #32bdc7;
}
.ha-rb .MuiIconButton-colorSecondary:hover,
.ha-rb .MuiRadio-colorSecondary.Mui-checked:hover {
  background-color: rgba(50, 189, 199, 0.08);
}
/* Homage radio button style */

/* homage checkbox style */
.ha-checkbox-cont {
  cursor: pointer;
}
.ha-checkbox-cont > span {
  display: block;
  margin-top: -1px;
  margin-left: 7px;
}
.ha-checkbox {
  width: 16px;
  height: 16px;
  padding: 0 !important;
  margin: 0 !important;
  color: #7c7c7c !important;
}
.ha-checkbox svg {
  font-size: 16px;
}
.ha-checkbox:hover {
  background: #fff !important;
}
.ha-checkbox.MuiCheckbox-colorSecondary.Mui-checked {
  color: #38bdc7 !important;
}

.ha-checkbox-big {
  width: 20px;
  height: 20px;
}
.ha-checkbox-big svg {
  font-size: 20px;
}

.ha-checkbox-disabled {
  width: 16px;
  height: 16px;
  padding: 0 !important;
  margin: 0 !important;
  color: #7c7c7c !important;
  opacity: 0.5;
  cursor: default;
}

.ha-checkbox-disabled svg {
  font-size: 16px;
}

.ha-checkbox-disabled.MuiCheckbox-colorSecondary.Mui-checked:disabled {
  color: #c9c9c9 !important;
}

/* end homage checkbox style */

/* Native green bordered buttons */
.green-border-btn,
.green-border-icon-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  outline: none;
  border: 1px solid #32bdc7;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 700;
  color: #32bdc7;
  cursor: pointer;
  transition: background-color 0.2s ease-out;
  min-height: 42px;
}
.green-border-btn {
  min-width: 131px;
  justify-content: center;
  padding: 10px 0px;
}
.green-border-btn[data-selected] {
  background: #84d7dd;
  color: #fff;
}

.green-border-icon-btn {
  padding: 17px 20px;
}
.green-border-icon-btn img {
  width: 40px;
  height: 40px;
  margin-right: 20px;
}
.green-border-icon-btn[data-selected] {
  background: #32bdc7;
  color: #fff;
}
.green-border-icon-btn[data-selected] img {
  filter: brightness(0) invert(1);
}

.green-border-icon-btn:hover,
.green-border-icon-btn:focus,
.green-border-btn:hover,
.green-border-btn:focus {
  background: #e8fcff;
}
.green-border-icon-btn[data-selected]:hover,
.green-border-icon-btn[data-selected]:focus {
  background: #2eadb6;
}
.green-border-icon-btn[disabled] {
  background: #b9faff !important;
  filter: grayscale(1);
  cursor: default;
}

.green-border-btn[data-selected]:hover,
.green-border-btn[data-selected]:focus {
  background: #7fcfd4;
}

/* End Native green bordered buttons */

.vertical-separator {
  width: 1px;
  min-width: 1px;
  background-color: #ececec;
  margin-left: 0px;
  margin-right: 8px;
}

.tri-up {
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid #4a4a4a;
  cursor: pointer;
}
.tri-down {
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #4a4a4a;
  margin-top: 3px;
  cursor: pointer;
}
.tri-up[is-selected] {
  border-bottom-color: #a4a4a4;
}
.tri-down[is-selected] {
  border-top-color: #a4a4a4;
}

.MuiPopover-root,
.MuiTooltip-popper {
  z-index: 4000 !important;
}

.co-others-chip ul {
  max-height: 200px;
  overflow-y: auto;
  margin-top: 5px;
}

.concierge-tag {
  padding: 5px 8px;
  margin-right: 8px;
  background-color: #f4f2ff;
  border-radius: 12px;
  color: #7360db;
  font-weight: 600;
  font-size: 11px;
  letter-spacing: 0.5px;
}

.stc-tf-error {
  font-family: Avenir;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-top: 8px;
  color: #d92f44;
}

.main-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
}
