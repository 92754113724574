.m-t-10 {
  margin-top: 10px;
}
.m-t-6 {
  margin-top: 6px;
}
.m-t-9 {
  margin-top: 9px;
}
.m-t-25 {
  margin-top: 25px;
}
.m-t-40 {
  margin-top: 40px;
}
.m-t-42 {
  margin-top: 42px;
}
.m-t-50 {
  margin-top: 50px;
}
.m-t-60 {
  margin-top: 60px;
}

.m-t-80 {
  margin-top: 80px;
}
.m-t-90 {
  margin-top: 90px;
}
.m-t-100 {
  margin-top: 100px;
}
.m-t-110 {
  margin-top: 110px;
}
.m-t-160 {
  margin-top: 160px !important;
}
.m-t-180 {
  margin-top: 180px !important;
}
.m-t-190 {
  margin-top: 190px !important;
}
.m-l-15 {
  margin-left: 15px;
}
.m-l-16 {
  margin-left: 16px;
}
.m-r-16 {
  margin-right: 16px;
}
.m-l-20 {
  margin-left: 20px;
}
.m-l-33 {
  margin-left: 33px;
}
.m-l-180 {
  margin-left: 180px !important;
}
.m-b-40 {
  margin-bottom: 40px;
}
.m-b-16 {
  margin-bottom: 16px;
}
.m-b-80 {
  margin-bottom: 80px;
}
.top-relative-1 {
  position: relative;
  top: 1px;
}
.b-relative-3 {
  position: relative;
  bottom: 3px;
}
.b-relative-10 {
  position: relative;
  bottom: 10px;
}
/* ///////////// Padding ////////////////// */
.p-0 {
  padding: 0 !important;
}
.p-1 {
  padding: 0.25rem !important;
}
.p-2 {
  padding: 0.5rem !important;
}
.p-3 {
  padding: 0.75rem !important;
}
.p-4 {
  padding: 1rem !important;
}
.p-6 {
  padding: 1.5rem !important;
}
.p-8 {
  padding: 2rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}
.pt-1 {
  padding-top: 0.25rem !important;
}
.pt-2 {
  padding-top: 0.5rem !important;
}
.pt-3 {
  padding-top: 0.75rem !important;
}
.pt-4 {
  padding-top: 1rem !important;
}
.pt-6 {
  padding-top: 1.5rem !important;
}
.pt-8 {
  padding-top: 2rem !important;
}
.pl-1 {
  padding-left: 0.25rem !important;
}
.pl-2 {
  padding-left: 0.5rem !important;
}
.pl-3 {
  padding-left: 0.75rem !important;
}
.pl-4 {
  padding-left: 1rem !important;
}
.pl-6 {
  padding-left: 1.5rem !important;
}
.pl-8 {
  padding-left: 2rem !important;
}
.pr-1 {
  padding-right: 0.25rem !important;
}
.pr-2 {
  padding-right: 0.5rem !important;
}
.pr-3 {
  padding-right: 0.75rem !important;
}
.pr-4 {
  padding-right: 1rem !important;
}
.pr-6 {
  padding-right: 1.5rem !important;
}
.pr-8 {
  padding-right: 2rem !important;
}
.pb-1 {
  padding-bottom: 0.25rem !important;
}
.pb-2 {
  padding-bottom: 0.5rem !important;
}
.pb-3 {
  padding-bottom: 0.75rem !important;
}
.pb-4 {
  padding-bottom: 1rem !important;
}
.pb-6 {
  padding-bottom: 1.5rem !important;
}
.pb-8 {
  padding-bottom: 2rem !important;
}
.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.px-1 {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}
.px-2 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}
.px-3 {
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important;
}
.px-4 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}
.px-6 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}
.px-8 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}
/* /////////////////////////////// */
/* ///////////// Margins ////////////////// */
.m-0 {
  margin: 0 !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.mt-1 {
  margin-top: 0.25rem !important;
}
.mt-2 {
  margin-top: 0.5rem !important;
}
.mt-3 {
  margin-top: 0.75rem !important;
}
.mt-4 {
  margin-top: 1rem !important;
}
.mt-6 {
  margin-top: 1.5rem !important;
}
.mt-8 {
  margin-top: 2rem !important;
}
.ml-1 {
  margin-left: 0.25rem !important;
}
.ml-2 {
  margin-left: 0.5rem !important;
}
.ml-3 {
  margin-left: 0.75rem !important;
}
.ml-4 {
  margin-left: 1rem !important;
}
.ml-6 {
  margin-left: 1.5rem !important;
}
.ml-8 {
  margin-left: 2rem !important;
}
.mr-1 {
  margin-right: 0.25rem !important;
}
.mr-2 {
  margin-right: 0.5rem !important;
}
.mr-neg-2 {
  margin-right: -0.5rem !important;
}
.mr-3 {
  margin-right: 0.75rem !important;
}
.mr-4 {
  margin-right: 1rem !important;
}
.mr-6 {
  margin-right: 1.5rem !important;
}
.mr-7 {
  margin-right: 1.75rem !important;
}
.mr-8 {
  margin-right: 2rem !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.mb-1 {
  margin-bottom: 0.25rem !important;
}
.mb-2 {
  margin-bottom: 0.5rem !important;
}
.mb-3 {
  margin-bottom: 0.75rem !important;
}
.mb-4 {
  margin-bottom: 1rem !important;
}
.mb-6 {
  margin-bottom: 1.5rem !important;
}
.mb-8 {
  margin-bottom: 2rem !important;
}
.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.mx-1 {
  margin-left: 0.25rem !important;
  margin-right: 0.25rem !important;
}
.mx-2 {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}
.mx-3 {
  margin-left: 0.75rem !important;
  margin-right: 0.75rem !important;
}
.mx-4 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}
.mx-6 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}
.mx-8 {
  margin-left: 2rem !important;
  margin-right: 2rem !important;
}
.mx-auto {
  margin: 0px auto !important;
}
.m-auto {
  margin: auto !important;
}
/* /////////////////////////////// */
.p-t-12 {
  padding-top: 12px !important;
}
.p-t-30 {
  padding-top: 30px !important;
}
.p-l-30 {
  padding-left: 30px !important;
}
.p-b-12 {
  padding-bottom: 12px !important;
}
.p-lr-20 {
  padding: 0 20px;
}
.w-7p {
  width: 7%;
}
.w-8p {
  width: 8%;
}
.w-10p {
  width: 10%;
}
.w-13p {
  width: 13%;
}
.w-17p {
  width: 17%;
}

.w-18p {
  width: 18%;
}
.w-22p {
  width: 22%;
}
.w-31p {
  width: 31%;
}

.w-60 {
  width: 60px !important;
}
.w-70 {
  width: 70px !important;
}
.w-90 {
  width: 90px !important;
}
.w-100 {
  width: 100px !important;
}
.w-110 {
  width: 110px !important;
}
.w-148 {
  width: 148px !important;
}
.w-180 {
  width: 180px !important;
}
.w-250 {
  width: 250px !important;
}
.max-w-250 {
  max-width: 250px;
}
.w-300 {
  width: 300px !important;
}
.w-500 {
  width: 500px !important;
}
.w-580 {
  min-width: 580px !important;
}
.w-600 {
  min-width: 600px !important;
}
.w-630 {
  width: 630px !important;
}
.min-w-110 {
  min-width: 110px;
}
.min-w-240 {
  min-width: 240px;
}
.w-full {
  width: 100% !important;
}
.fs-12 {
  font-size: 12px;
}
.fs-13 {
  font-size: 13px;
}
.fs-14 {
  font-size: 14px;
}
.fs-16 {
  font-size: 16px;
}
.fs-17 {
  font-size: 17px;
}
.fs-24 {
  font-size: 24px !important;
}

.fs-64 {
  font-size: 64px;
}
.f-bold {
  font-weight: bold;
}
.max-w-660 {
  max-width: 660px !important;
}
.h-min-150 {
  min-height: 150px;
}
.h-max {
  height: 2800px !important;
}

.s2 {
  animation-delay: 2s;
}
.s5 {
  animation-delay: 5s;
}
.overflow-x {
  overflow-x: auto;
}
.text-capital {
  text-transform: capitalize;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.curson-pointer {
  cursor: pointer;
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-default {
  cursor: default !important;
}
.curson-text {
  cursor: text;
}
#blue-color {
  background-color: #6574c4 !important;
}
.text-red {
  color: red !important;
}
.display_block {
  display: block !important;
}
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.z-0 {
  z-index: 0;
}
.z-1 {
  z-index: 1;
}
.z-2 {
  z-index: 2;
}
.flex {
  display: flex;
}
.flex-nowrap {
  flex-flow: nowrap;
}
.justify-around {
  justify-content: space-around;
}
.justify-between {
  justify-content: space-between;
}
.justify-center {
  justify-content: center;
}
.justify-end {
  justify-content: flex-end;
}
.justify-start {
  justify-content: flex-start;
}
.align-center {
  align-items: center;
}
.absolute-center {
  position: absolute;
  left: 50%;
}
.absolute-center > * {
  position: relative;
  left: -50%;
}
.inline {
  display: inline !important;
}
.hidden {
  display: none !important;
}
.bold {
  font-weight: bold;
}
.normal {
  font-weight: normal;
}

.bg-white {
  background-color: #fff !important;
}
.bg-transparent {
  background: transparent !important;
}
.half-opacity {
  opacity: 0.5;
}
.disable-events {
  pointer-events: none !important;
}
.nowrap {
  white-space: nowrap;
}
.as-fs {
  align-self: flex-start;
}
.as-center {
  align-self: center;
}
.grow {
  flex-grow: 1;
}
.dashed-hr {
  border-top: 1px dashed rgb(219, 225, 232);
}
.row-gap-1rem {
  row-gap: 1rem;
}
.row-gap-10px {
  grid-row-gap: 10px;
  row-gap: 10px;
}
/* ////////////////////////////////////////////////// */
.app {
  width: 100%;
}
.grey-primary {
  color: #757588;
}
.grey-secondary {
  color: #7c7c7c;
}
.heading-color {
  color: #32bdc7;
}
.heading-size {
  font-size: 16px;
}
.navigation-bar {
  border: 1px solid #32bdc7;
  background-color: #32bdc7;
  height: 3.25rem;
  padding: 0 1.25rem;
  box-sizing: border-box;
  flex-shrink: 0;
}
.navigation-bar .homage-logo {
  width: 1.875rem;
  margin-right: 0.625rem;
  cursor: pointer;
}
.navigation-bar .homage-title {
  color: #fff;
  font-size: 0.875rem;
  font-weight: bold;
}

.app-section {
  width: 100%;
  height: 100%;
  flex-direction: row;
  display: flex; /*enables flex content for its children*/
  box-sizing: border-box;
}

.content-section {
  width: 100%;
}

.sidebar-menu + .content-section {
  width: calc(100% - 230px);
}

.sidebar-collapsed + .content-section {
  width: calc(100% - 50px);
}
.logout {
  text-align: center;
  border-radius: 4px;
  border: 1px solid #fff;
  color: #fff;
  margin-left: auto;
  width: 100px;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}

.app-spinner-cont {
  border-radius: 4px;
  background: transparent;
  padding: 10px;
  padding-bottom: 6px;
}
.app .loading-spinner {
  z-index: 3005;
  margin-left: 25px;
  margin-top: 8px;
}

.app-snackbars {
  z-index: 3004;
}
.MuiOutlinedInput-notchedOutline {
  border-color: #dbe1e8;
  border-radius: 8px;
}
.MuiSelect-iconOutlined {
  color: #b8babe;
}
.MuiFormControl-root {
  border: 0;
  margin: 0;
  display: inline-flex;
  padding: 0;
  position: relative;
  flex-direction: column;
  vertical-align: top;
}
.MuiInputLabel-outlined {
  z-index: 1;
  transform: translate(14px, 20px) scale(1);
  pointer-events: none;
}
.MuiInputLabel-formControl {
  top: 0;
  left: 0;
  position: absolute;
}
.MuiFormLabel-root {
  color: rgba(0, 0, 0, 0.54);
  padding: 0;
  font-size: 1rem;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.00938em;
}

.pac-container {
  background-color: #fafbfd;
  border-radius: 5px;
}
.pac-item {
  line-height: 36px;
  color: #5a5a73;
}
.pac-icon {
  margin-top: 8px;
  margin-left: 4px;
}
.pac-logo:after {
  margin-right: 8px;
  margin-bottom: 8px;
  height: 15px;
}
.mp-border-b {
  border-bottom: 1px solid rgb(219, 225, 232, 0.4);
}
.package-detail {
  background: #f6f6f8;
  max-height: 500px;
  overflow-y: auto;
  border-radius: 8px;
}
.package-detail .bg-img {
  height: 72px;
  background-image: url('/self-serve/package-popup-header@3x.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.package-detail .active-tab {
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.04);
  float: right;
  margin: 12px 17px 0px 0px;
}
.package-detail .pd-slider {
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.04);
}
.package-detail .pd-tr-item {
  box-shadow: 0px 0px 3px 1px rgb(0 0 0 / 4%);
  margin-top: 6px;
}

.package-transaction {
  max-height: 500px;
  overflow-y: auto;
}
.package-transaction .bg-img {
  height: 66px;
  background-image: url('/self-serve/package-popup-header@3x.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.package-transaction .pd-tr-item {
  box-shadow: 0px 0px 3px 1px rgb(0 0 0 / 4%);
  margin-top: 6px;
}
.package-transaction .active-tab {
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.04);
  float: right;
  margin: 12px 17px 0px 0px;
}
.package-transaction .link-icon:hover {
  background: rgba(50, 189, 199, 0.07);
}
.package-transaction .app-link-w {
  width: 355px;
}
.package-transaction .message {
  position: absolute;
  top: -39px;
  background: #f6fffa;
  height: 30px;
  width: 308px;
  color: #22234e;
  left: -150px;
  border-radius: 8px;
  border: 1px solid #4cda8d;
}
.address-save-separater {
  border-right: 1px solid #dbe1e8;
  height: 42px;
}
.lat-long .normal-imp {
  font-weight: normal !important;
}
.disable-save-address-btn {
  background: #dbe1e8;
  color: #9898a6;
}

.delete-modal {
  width: 640px;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
  padding: 21px 30px 16px 30px;
  font-size: 14px;
  color: #4a4a4a;
}
.modal-header {
  border-bottom: 1px solid #ececec;
}

.ld-location-row {
  border-bottom: 1px solid #ececec;
  padding: 3px 0;
  padding-left: 8px;
  cursor: pointer;
}
.ld-location-row:last-child {
  padding-bottom: 0;
  border: 0;
}

.ld .li-save-btn {
  margin-top: 0 !important;
}

.ld .if-input[has-focus] {
  border-bottom: 2px solid #32bdc7;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.ld-location-popup {
  top: 53.52%;
  left: 25.74%;
  opacity: 1 !important;
  transform: unset !important;
  display: flex !important;
  flex-direction: column;
  box-shadow: 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%), 0 2px 4px -1px rgb(0 0 0 / 20%);
  padding: 15px;
  padding-bottom: 0;
  width: 60%;
}
.ld .li-done-all {
  margin-top: 0 !important;
}
.ld-location-popup .ld-location-row {
  padding: 8px 0;
  padding-left: 8px;
}
.ld-location-popup .ld-location-row:last-child {
  padding-bottom: 0;
}
.ld-location-popup .ld-location-row:first-child {
  padding-top: 0;
}
.location-error-highlight {
  line-height: 1.2 !important;
  border: 1px solid red !important;
  color: #e64974 !important;
  background: #fff0f0 !important;
}
