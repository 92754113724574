.input-field {
  position: relative;
  display: block;
  font-size: inherit;
  line-height: inherit;
}

.if-input {
  border: 0;
  font-size: inherit;
  font-weight: 400;
  line-height: inherit;
  /* color: #4a4a4a; */
  padding: 0;
  color: inherit;
  outline: 0;
  resize: none;
  background: transparent;
  transition: background-color 0.3s ease-in-out, width 0.15s, height 0.15s;
}

.if-input[has-focus],
.if-input:hover {
  background: #f1f3f4;
}

.if-input[has-error] {
  background: #fce8e6;
  margin-right: 1px;
}

.if-input-field {
  position: relative;
  padding: 4px 8px;
  border-radius: 4px;
  box-sizing: content-box;
  white-space: pre-wrap;
}

.if-measure-label {
  padding: 0 0;
  white-space: pre-wrap;
  /* white-space: pre-line; */
}

.if-type-date {
  min-width: 4.25rem;
  max-width: 7.3rem;
}

.if-type-time {
  min-width: 1.8125rem;
  max-width: 3.5rem;
}

.if-bottom-bar {
  position: absolute;
  height: 2px;
  bottom: 0;
  left: 0;
  right: 0;
  transform: scaleX(0);
  background-color: #32bdc7;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  transition: transform 0.2s cubic-bezier(0.46, -0.01, 0.26, 0.79);
  opacity: 0;
}

.if-input[has-focus] + .if-bottom-bar {
  transform: scaleX(1);
  opacity: 1;
}

.if-popup {
  position: relative;
  display: none;
  background-color: #fff;
  position: absolute;
  border-radius: 2px;
  opacity: 0;
  transform: translateY(-5px);
  transition: transform 0.3s, opacity 0.2s;
}

.if-popup[has-focus] {
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
}

.if-popup-date {
  padding: 0.625rem 0.625rem 0.25rem;
  width: 13.0625rem;
}

.if-fixed-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2000;
  cursor: default;
}
