html {
  /* font-size: 12px; */
  font-size: 16px;
}

/* @import url('https://fonts.googleapis.com/css?family=Lato:300,400,700,900&display=swap'); */
/* @import url('https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,800&display=swap'); */

@font-face {
  font-family: avenir;
  src: url(/fonts/AvenirLight.woff2) format('woff2');
  font-weight: 300;
}

@font-face {
  font-family: avenir;
  src: url(/fonts/AvenirRoman.woff2) format('woff2');
  font-weight: 400;
}

@font-face {
  font-family: avenir;
  src: url(/fonts/AvenirMedium.woff2) format('woff2');
  font-weight: 500;
}
@font-face {
  font-family: avenir;
  src: url(/fonts/AvenirHeavy.tff) format('ttf');
  font-weight: 600;
}

body {
  margin: 0;
  font-family: 'avenir', sans-serif;
  /* font-family: 'Nunito Sans', sans-serif; */

  /* -moz-osx-font-smoothing: grayscale; */
  /* -webkit-text-size-adjust: 100%; */
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

* {
  font-family: 'avenir', sans-serif !important;
  /* font-family: 'Nunito Sans', sans-serif !important; */
}

html,
body,
#root {
  display: flex;
  height: 100%;
  width: 100%;
}

*,
:after,
:before {
  box-sizing: border-box;
}
