/* Modal Header */
.send-notification-dialog-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 24px;
  margin-bottom: 16px;
}

.send-notification-dialog-header h3 {
  color: #4a4a4a;
  margin: 0;
  font-size: 22px;
  font-weight: 400;
  line-height: 27.65px;
}

.send-notification-dialog-header .MuiIconButton-root {
  padding: 4px;
}

/* Modal Content */
.send-notification-dialog-content {
  padding-left: 24px;
  padding-right: 24px;
}

.send-notification-tabs {
  display: flex;
  position: relative;
  margin-bottom: 16px;
}

.tab {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 138px;
  min-height: 40px;
  cursor: pointer;
  border-bottom: 1px solid #dbdbdb;
  color: #00a0ac;
  font-size: 14px;
  font-weight: 500;
  line-height: 19.12px;
  background-color: white;
}

.tab.selected {
  border-top: 1px solid #dbdbdb;
  border-left: 1px solid #dbdbdb;
  border-right: 1px solid #dbdbdb;
  border-bottom: none; /* Remove the bottom border for the selected tab */
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  color: #4a4a4a; /* Highlight the selected tab's text */
}

.tab-underline {
  flex-grow: 1;
  border-bottom: 1px solid #dbdbdb;
}

.template-dropdown {
  position: relative; /* Create a positioned parent for absolute positioning of the custom arrow */
  margin-bottom: 16px;
}

.template-dropdown label {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 4px;
  display: block;
}

.template-dropdown {
  display: flex;
  flex-direction: row;
  gap: 16px;
  padding-left: 16px;
  align-items: center;
}

.template-dropdown label {
  font-size: 14px;
  font-weight: 400;
  padding: 0px;
  color: #212121;
}

.template-dropdown select {
  appearance: none; /* Remove the default arrow */
  -webkit-appearance: none; /* Remove default arrow in Safari */
  -moz-appearance: none; /* Remove default arrow in Firefox */
  background: url('/dropdown-down-arrow.png') no-repeat right 10px center; /* Add your custom arrow */
  background-size: 12px;
  padding-right: 30px; /* Add space for the custom arrow */

  width: 100%;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #d0d0d0;
  border-radius: 4px;
  background-color: white;
  cursor: pointer;

  font-weight: 400;
  color: #212121;
}

.template-dropdown select:focus {
  outline: none;
  border-color: #00bdcb;
}

.template-preview {
  padding: 16px;
  border-radius: 4px;
  background-color: #f6f6f6;
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #838383;
  font-size: 14px;
  font-weight: 400;
  max-height: 300px;
  /* flex-grow: 1; */
  /* height: 100%; */
}

.no-template-selected {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.no-template-selected p {
  margin: 8px 0;
}

.no-template-title {
  font-size: 16px;
  line-height: 21.86px;
}

.no-template-selected img {
  width: 120px;
  height: 120px;
  margin-bottom: 24px;
}

/* Modal Footer */
.send-notification-dialog-footer {
  display: flex;
  justify-content: center;
  padding: 16px;
}

.dialog-footer-submit-button {
  padding: 8px 16px;
  font-size: 16px;
  font-weight: 500;
  color: white;
  background-color: #00bdcb;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  max-width: 200px;
}

.dialog-footer-submit-button:disabled {
  background-color: #dbdbdb;
  cursor: not-allowed;
}

.dialog-footer-submit-button:not(:disabled):hover {
  background-color: #00bdcb;
}

.pre-wrap {
  white-space: pre-wrap;
}

.custom-button {
  background: url('/variable_key_bold.png') no-repeat center; /* Add your custom arrow */
  background-size: 20px;
  background-color: #61626200;
}
.custom-button:hover {
  background: url('/variable_key_bold.png') no-repeat center; /* Add your custom arrow */
  background-size: 20px;
  background-color: #61626200;
}
.custom-button[has-focus],
.custom-button:hover {
  background: url('/variable_key_bold.png') no-repeat center; /* Add your custom arrow */
  background-size: 20px;
  background-color: #7d83832c;
}
