.cp-dashboard-container {
  display: flex;
  flex-direction: column;
  min-height: 0; /* This is the key to make the div grow to fit its content */
  height: 100%;
  padding-top: 24px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 32px;
  background-color: #ecf0f5;
  overflow-x: auto;
}

.cp-dashboard-body {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  border-radius: 5px;
  background-color: white;
  overflow-x: auto;
  padding: 24px;
  gap: 16px;
  flex: 1;
}

.cp-dashboard-content {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  border-bottom: 1px solid #dbdbdb;
}

.cp-dashboard-entries {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.cp-dashboard-entries > div {
  font-size: 14px;
}

.cp-dashboard-bottom-row {
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
}

.cp-dashboard-total-entries {
  display: inline-block;
  font-weight: 500;
  font-size: 14px;
}
